import React, { MouseEvent } from "react"
import { Button, makeStyles } from "@material-ui/core"
import withWidth, { isWidthUp } from "@material-ui/core/withWidth"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import { Language } from "@material-ui/icons"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(({ palette }) => ({
  button: {
    fontWeight: "bold",
    color: ({ lightText }) =>
      lightText ? palette.primary.contrastText : "initial",
  },
}))

interface Props {
  width: Breakpoint
  lightText?: boolean
}

const LanguageSelectTemplate = ({ width, lightText }: Props) => {
  const { i18n } = useTranslation()
  const classes = useStyles({ lightText })
  const toggleLanguage = (_: MouseEvent<HTMLButtonElement>) => {
    switch (i18n.language) {
      case "lt-LT":
        i18n.changeLanguage("en-US")
        break
      case "en-US":
        i18n.changeLanguage("uk-UA")
        break
      case "uk-UA":
        i18n.changeLanguage("lt-LT")
        break
    }
  }

  return (
    <Button
      startIcon={isWidthUp("md", width) ? <Language /> : undefined}
      className={classes.button}
      onClick={toggleLanguage}
    >
      {i18n.language === "lt-LT" && "LT"}
      {i18n.language === "en-US" && "EN"}
      {i18n.language === "uk-UA" && "UA"}
    </Button>
  )
}

export const LanguageSelect = withWidth()(LanguageSelectTemplate)
