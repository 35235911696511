export default {
  HOME: '/',
  ABOUT: '/about',
  LOGIN: '/login',
  MAP: '/map',
  REGISTER: '/register',
  PARTICIPATE: {
    HOME: '/participate',
    LOGIN: '/participate/login',
    PROFILE: `/participate/:performerId/profile`,
    CREATE: '/participate/create',
    EDIT_PERFORMER: '/participate/:performerId/edit',
    EDIT_PERFORMANCE: '/participate/:performerId/performance/:performanceId',
    ADD_PERFORMANCE: '/participate/:performerId/performance/add',
  },
  ADMIN: {
    HOME: '/hood',
    EVENTS: '/hood/events',
    CITIES: '/hood/cities',
    LOCATIONS: '/hood/locations',
    PERFORMANCES: '/hood/performances',
    PERFORMERS: '/hood/performers',
    GENRES: '/hood/genres',
    SPONSORS: '/hood/sponsors',
    CITY_ADMINS: '/hood/city-admins',
    SETTINGS: '/hood/settings',
    STATISTICS: '/hood/statistics'
  },
  FAQ: '/rules',
  CONTACTS: '/contacts',
  SPONSORS: '/sponsors',
  RESET_PASSWORD: '/reset-password',
}
